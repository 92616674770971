<!-- @format -->

<template>
  <div
    class="text-left border bg-white/30 hover:border-white border-neutral-200 rounded-lg hover:shadow-md overflow-hidden hover:-translate-y-[2px] hover:border-neutral-300 transition-all flex flex-col h-full"
  >
    <div class="bg-white-50 flex flex-col pb-0 relative">
      <!-- //SSG BADGE -->
      <div
        class="absolute z-30 right-0 top-0 px-2 rounded-b-lg bg-white text-sky-500 shadow-lg mr-4 flex-shrink"
        v-if="course.is_funded"
      >
        <img class="h-8 w-16 object-contain" src="@/assets/ssg_logo_coloured.jpg" alt="" />
      </div>

      <div class="flex h-40">
        <img
          :src="course.image_url"
          class="h-38 object-cover w-full rounded-t-md border-b-2 border-white h-44"
          alt=""
        />
      </div>

      <div
        class="absolute left-0 bottom-0 px-3 py-1 rounded-r-xl border border-white z-20 bg-lime-500 border-l-0 text-white font-bold text-md mb-2"
      >
        <div>
          <div class="text-xs text-white/75">Course Fee</div>
          <div class="leading-none">
            {{ course.default_currency }} {{ course.default_fee }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-grow flex flex-col">
      <div
        class="mb-4 bg-white shadow-lg shadow-black/5 text-lg p-4 font-semibold text-sky-800 border-b border-neutral-300/75 flex-grow flex flex-col justify-end"
      >
        <div class="py-2">{{ course.name }}</div>
        <div
          class="flex justify-between items-end pt-2 order-t border-t border-dashed border-neutral-500"
        >
          <div class="flex-1">
            <div class="text-sm text-light pt-2 b flex-shrink">
              By: <span class="text-pink-600">{{ course.client.name }}</span>
            </div>
          </div>

          <div class="cursor-pointer text-sm font-light"
          :class="
                !course.is_liked ? 'text-neutral-300 hover:text-rose-400' : 'text-rose-400'
              "
          >
            <fa-icon
              icon="heart"              
              @click="sendLike"
            ></fa-icon>
            <span
            class="px-1"
              
            >
              {{ course.likes_count }}</span
            >
          </div>
        </div>
      </div>

      <!-- ======================================== -->
      <div class="px-4 pb-4">
        <!-- <div class="border-b text-sm border-dashed border-neutral-300 text-neutral-400">Course Sessions Time</div> -->
        <div class="flex flex-wrap">
          <!-- <CognotivBadge>Daily</CognotivBadge> -->
<!--           <CognotivBadge>Wednesday</CognotivBadge> <CognotivBadge>Friday</CognotivBadge>
          <CognotivBadge>Saturday</CognotivBadge> -->
          <CognotivBadge v-if="course.start_at != null || course.ends_at != null"
          colors="text-sky-800 bg-sky-100 border border-sky-200"
            >{{ course.start_at }} to {{ course.ends_at }}</CognotivBadge
          >
          <CognotivBadge colors="text-rose-800 bg-rose-100 border border-rose-200">{{
            course.training_mode
          }}</CognotivBadge>
        </div>
      </div>

      <!-- ======================================== -->
      <!--             <div class="px-4 py-2 bg-white">
                <div class="text-sm text-neutral-400 flex justify-between">
                    <div>Mode Of Training</div>
                    <div class="font-bold text-lime-600">Full Time</div>
                </div>

            </div> -->

      <!-- ======================================== -->

      <div class="px-4 pb-2 pt-0 bg-white">
        <div class="flex space-x-2 py-2">
          <FlatButton
            backgroundColor="bg-indigo-500 hover:bg-indigo-600"
            @click="$emit('findOut', course)"
            >Find Out More</FlatButton
          >

          <div v-if="course.has_taken" class="flex-grow justify-center">
            <FlatButton
              backgroundColor="bg-green-500 hover:bg-green-600"
              @click="recommend"
            >
              Recommend
            </FlatButton>
          </div>
          <FlatButton
            v-else
            backgroundColor="bg-purple-500 hover:bg-purple-600"
            @click="$emit('signUp', course)"
            >Sign Up Now</FlatButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CognotivBadge from "@/components/CognotivBadge.vue";
import FlatButton from "@/components/FlatButton.vue";
export default defineComponent({
  props: {
    course: Object,
  },
  methods: {
    async recommend() {
/*       let ask = await this.$swal.fire({
        title: "Recommend Course",
        text: "Please fill in email address",
        showCancelButton: true,
        confirmButtonText: "Send",
        input: "text",
      });
      if (ask.isConfirmed) {
        this.$swal.fire("This feature is coming soon");
      } */
    },
    sendLike() {
      if (this.course.is_liked) {
          return null;
        /*  return this.$toast.open({
          message: "Already Liked",
          type: "info",
          duration: 4500,
          dismissible: true, */
        //});
      }
      //let path = route("pandaicorner.like.course", { course: this.course.id });
      //return this.$inertia.post(path);
    },
  },
  components: {
    CognotivBadge,
    FlatButton,
  },
});
</script>
<style lang=""></style>

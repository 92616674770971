import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Slideout from '@hyjiacan/vue-slideout'
library.add(fas);
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@hyjiacan/vue-slideout/dist/slideout.css'
import "./app.css";



createApp(App)
  .use(store)
  .use(router)
  .use(Slideout)
  .use(VueSweetalert2)
  .component("fa-icon", FontAwesomeIcon)
  .mount("#app");

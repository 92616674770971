
import axios from 'axios';
import { baseURL } from "@/data/appdata";


const state = {
  status: "unknown",
  user: null,
  token: null,
};

const mutations = {
  setAuthToken(state, { token }) {
    state.token = token;
  },
  setUserData(state, {userdata}){
    state.user = userdata;
  },  
  clearAuth(state){
    state.user = null;
    state.token = null;
  }
};

const actions = {

  async getUserData({commit, state}){
    if(!state.token){
      console.log('token not set');
      return null;
    }

    const token = state.token;

    const axiosClient = axios.create({
      baseURL:baseURL,   
  })
    axiosClient.defaults.headers.common['Authorization'] = "Bearer "+token;

    const {data} = await axiosClient.get('authdata');
    console.log('userdata:'+ data);
    return commit('setUserData', {userdata:data})
  },
  async saveAuthToken({ commit }, {token}) {
    console.log('setting token:'+token);
    return await commit("setAuthToken", { token:token });
  },
  async fetchLocalAuthState({ commit }) {
    commit("SET_AUTH");
  },
  async logoutAction({commit}){
    console.log('logging out');
    commit('clearAuth')
  }
};

const getters = {
  getAuthToken(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return state.user != null && state.token != null;
  },
  getUserData(state){
    return state.user;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};

import {get, post} from './api.service';

export async function initMarketplace(){    
    try {
        const {data} = await get('marketplace_init/');
        return [null, data];
    } catch (error) {
        console.log(error);
        return [error];
    }
}

export async function getCourses(){
    try {
        const {data} = await get('courses/');
        
        return [null, data];
    } catch (error) {
        return [error];
    }
}


export async function getCategoryCourses(catId){
    try {
        console.log(catId);
        const {data} = await get('get_categorized_courses/'+catId)
        
        return  data
    } catch (error) {
        return error;
    }
}


export async function signUpAsGuest(payload){
    try {
        const {response} = await post('guest_signup', payload);
        return [null, response];
    } catch (error) {
        return [error]
    }
}
<!-- @format -->

<template>
  <div class="p-2">
    <div class="mx-auto">
      <div class="grid sm:grid-cols-2 gap-2 mb-2">
        <div class="flex">
          <div class="w-full rounded-lg overflow-hidden border border-white bg-white">
            <img
              :src="course.image_url"
              class="h-48 w-96 mx-auto object-cover rounded-lg"
              :alt="course.name"
            />
          </div>
        </div>

        <div class="flex max-w-96">
          <div
            class="w-full rounded-lg border border-white bg-white/50 p-3 h-full items-center justify-center flex flex-col"
          >
            <div class="text-lg font-light text-neutral-500">Course Fee</div>
            <div class="font-black text-xl text-lime-600">
              {{ course.default_currency }} {{ course.default_fee }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex-1 border lg:mt-2 w-full flex flex-col md:w-auto md:flex-grow rounded-lg bg-white mt-2 md:mt-0 p-4"
      >
        <div
          class="mb-2 pb-2 text-purple-700 font-black text-lg leading-none border-b border-dashed border-purple-200"
        >
          {{ course.name }}
        </div>
        <div class="flex-grow">
          <div class="flex flex-wrap mt-2">
            <!-- <CognotivBadge>Daily</CognotivBadge> -->
            <CognotivBadge>Wednesday</CognotivBadge>
            <CognotivBadge>Friday</CognotivBadge>
            <CognotivBadge>Saturday</CognotivBadge>
            <CognotivBadge colors="text-sky-800 bg-sky-100 border border-sky-200"
              >2:30 PM to 3:50 PM
            </CognotivBadge>
            <CognotivBadge colors="text-rose-800 bg-rose-100 border border-rose-200">{{
              course.training_mode
            }}</CognotivBadge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import CognotivBadge from "./CognotivBadge.vue";
export default defineComponent({
  props: {
    course: Object,
  },
  components: { CognotivBadge },
});
</script>
<style lang=""></style>

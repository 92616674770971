import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";


import modules from './modules'

const store = createStore({
  getters:{
    testToken(state){
      return state.Auth.token;
    }
  },
  modules,
  plugins:[
    createMutationsSharer({predicate:["setAuthToken", "setUserData"]}),
    createPersistedState(),
  ]
})


/* Object.keys(modules).forEach(k => {
  store.registerModule(k, modules[k])
}) */


export default store;
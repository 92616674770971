
import store from "@/store/index";
import axios from 'axios';
import {baseURL} from "./appdata";
const authToken = store.getters.getAuthToken;
console.log('api service, authToken:'+authToken);

const axiosConfig  = {
    baseURL:baseURL,   
};

const axiosClient = axios.create(axiosConfig)
axiosClient.defaults.headers.common['Authorization'] = "Bearer "+authToken;

export async function get(path){
    return await axiosClient.get(path);
}

export async function post(path, payload){
    return await axiosClient.post(path, payload);
}